<template>
  <div class="registration_view">
    <a-form :form="form">
      <div class="main-label">Восстановление пароля</div>

      <a-form-item :validateStatus="statusEmail" :help="helpEmail">
        <a-input
          class="a-form-item-input"
          placeholder="Почта:"
          :value="form.email"
          v-on:input="updateFormEmail($event)"
          size="large"
        >
          <template v-slot:prefix>
            <img style="margin-right: 12px" src="./svg/mail.svg" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item>
        <div class="buttons">
          <a-button class="registration-button" @click="submitForm()">
            Восстановить
          </a-button>

          <a-button class="registration-button-active" @click="returnLogin()">
            Назад
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { validateEmail } from '@/helpers/validation'

export default {
  components: {},
  data () {
    return {
      statusEmail: 'success',
      helpEmail: '',
      form: {
        email: ''
      }
    }
  },
  methods: {
    submitForm () {
      if (this.form.email === '') {
        this.statusEmail = 'error'
        this.helpEmail = 'Wrong email'
      }
      if (!validateEmail(this.form.email)) {
        this.statusEmail = 'error'
        this.helpEmail = 'Некорректная почта'
      }

      if (this.statusEmail === 'success') {
        this.sendForm()
      }
    },

    updateFormEmail (e) {
      this.form.email = e.target.value
      this.statusEmail = 'success'
      this.helpEmail = ''
    },

    sendForm () {
      this.$http.post(process.env.VUE_APP_SERVER_URL + '/reset-password', this.form)
        .then((response) => {
          consol.log(response, 'response')
          return response.json()
        })
        .then((data) => {
          if (data.success) {
            this.userPersonalData = data.data.token
            this.$router.push('/login')
          } else {
            this.$notification.open({
              message: 'Ошибка входа в систему регистрации',
              description: data.message
            })
          }
        })
        .catch((error) => {
          let description = error.response.data.message
          Object.keys(error.response.data.data || {}).forEach((key) =>
            error.response.data.data[key].forEach((text) => {
              description += text + ', '
            })
          )

          this.$notification.open({
            message: 'Сетевая ошибка входа в систему',
            description
          })
        })
    },
    returnLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
@import "../../assets/themes/layout.css";
/* ////// */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}
.main-label {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 23px;
}

.a-form-item-input {
  height: 40px;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;

  /* justify-content: space-between; */
}

.registration-button {
  height: 40px;
  width: 100%;
  margin-right: 14px;

  color: white;
  background-color: #b12021;
}

.registration-button-active {
  height: 40px;
  width: 100%;

  background-color: white;
  color: #b12021;
}
</style>
